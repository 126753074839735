import { InitialStateInterface, initialState } from "./initialState";
import { Auth } from "../services";

export type Action = { type: string; payload: any };

export const reducer = (state: InitialStateInterface = initialState, action: Action) => {
	switch (action.type) {
		case "LOGGED_IN":
			return { ...state, loggedIn: true }
		case "LOGOUT":
			if (state.loggedIn) {
				Auth.logout();
			}
			return initialState
		case "SET_USER":
			return { ...state, user: action.payload }
		case "CHECK_TOKEN_LOADING":
			return { ...state, checkTokenLoading: action.payload }
		case "SET_CURRENTSITE":
			return { ...state, currentSite: action.payload, user: {...(state.user || {}),site:action.payload} as any }
		case "REFRESH_SITE":
			return { ...state, refreshSite: !state.refreshSite }
		default:
			return state;
	}
};
