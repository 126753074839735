import React, { useEffect, useState } from "react";
import { MailSettingService } from "../../../services";
import { useStore } from "../../../store";
import { Button, Form, Input, Space, notification } from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";


export default function MailSetting() {
  const {
    state: { user },
  } = useStore();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
    return () => {};
  }, [form]);

  const loadMailSettings = async () => {
    const site = user?.site;
    if (site) {
      try {
        const mails = await MailSettingService.getMailSettings(site?.id);
        form.setFieldsValue({
          emails: mails.length > 0 ? mails.map((value) => ({ value })): [{ value: null }],
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (user?.site) {
      loadMailSettings();
    }
    return () => {};
  }, [user?.site]);

  const onFinish = (data: any) => {
    setLoading(true);
    form
      .validateFields()
      .then(async () => {
        try {
          const response = await MailSettingService.create({...form.getFieldsValue(),siteId: user?.site?.id,});
          notification.success({ message: "E-mail sauvegardé" });
        } catch (error: any) {
          notification.error({ message: error?.message || "Erreur inconnue!" });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (data: any) => {
    console.log("Failed ", data);
  };

  const checkUniqueEmail = (_: any, value: any) => {
    const values: any[] = form.getFieldValue("emails");

    if (values.length && values.filter((v) => v.value === value).length > 1) {
      return Promise.reject(new Error("Email déjà renseigné"));
    }

    return Promise.resolve();
  };

  return (
    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
      <Form
        initialValues={{
          emails: [
            {
              value: null,
            },
          ],
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        style={{ width: 600, }}
        layout="vertical"
      >
        <Form.List rules={[]} name="emails">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <Space.Compact
                    block
                    key={key + "-mails"}
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name={[name, "value"]}
                      style={{ width: "100%" }}
					  
                      rules={[
                        {
                          required: true,
                          message: "Champ requis",
                        },
                        {
                          type: "email",
                          message: "Invalide email",
                        },
                        {
                          validator: checkUniqueEmail,
                        },
                      ]}
                      {...restField}
                    >
                      <Input />
                    </Form.Item>

                    {index > 0 ? (
                      <Form.Item style={{ marginLeft: 20 }}>
                        <MinusCircleOutlined
                          style={{ color: "red" }}
                          onClick={() => remove(name)}
                        />
                      </Form.Item>
                    ) : null}

                    {fields.length - 1 === index ? (
                      <Form.Item style={{ marginLeft: 20 }}>
                        <PlusCircleOutlined onClick={() => add()} />
                      </Form.Item>
                    ) : null}
                  </Space.Compact>
                );
              })}
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button
            className="bg-blue"
            type="primary"
            htmlType="submit"
            disabled={loading}
            block
            loading={loading}
          >
            Sauvegarder
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
