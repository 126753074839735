import { Invoice } from '../models';
import { http } from './axios';

export class InvoiceService {
	public static getInvoiceDetailsByReportAndInvoiceId(reportId: number, invoiceId: number): Promise<Invoice> {
		return http.get(`/invoices/${reportId}/${invoiceId}`);
	}

	public static getInvoices(siteId: number, status:string): Promise<Invoice[]> {
		return http.get(`/invoices/${siteId}`, {params:{status}});
	}
}