import { User } from '../models';
import { http } from './axios';

export class Auth {
	public static login(data: User) {
		return http.post('/auth/login', data);
	}

	public static logout(reload: boolean = true) {
		return http
			.get("/auth/logout")
			.then(() => {
				localStorage.clear();
				if (reload) {
					window.location.reload();
				}
				return 'success'
			})
			.catch(e => {
				if (reload) {
					window.location.reload();
				}
				window.location.reload();
				return e
			})
	}

	public static checkRefreshToken() {
		return http.get('/auth/check-refresh-token');
	}

	public static checkToken(token: string) {
		return http.post('/auth/check-access-token', { token });
	}

	public static refresh() {
		return http.post(`/auth/refresh`, {}).then((data: any) => ({ accessToken: data.accessToken }));
	}
}
