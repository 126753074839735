import { Site } from '.';

export class Profile {
	id!: number;
	firstName!: string;
	lastName!: string;
	address!: string;
	site!: Site;
	type!: string;
	roles!: string[]
}