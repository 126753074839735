import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import { Provider } from './store';
import { ConfigProvider } from 'antd';

import FR from 'antd/lib/locale/fr_FR';

import * as moment from 'moment';

import 'moment/locale/fr';

moment.locale('fr');

function App() {
    return (
        <BrowserRouter>
                <Provider>
                    <ConfigProvider locale={FR}>
                        <AppRoutes />
                    </ConfigProvider>
                </Provider>
        </BrowserRouter>
    );
}

export default App;
