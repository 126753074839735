import { Profile, Site } from '../models';

export interface InitialStateInterface {
	loggedIn: boolean;
	checkTokenLoading: boolean,
	currentSite: Site,
	refreshSite: boolean,
	user?: Profile;
}

export const initialState: InitialStateInterface = {
	loggedIn: false,
	user: undefined,
	checkTokenLoading: false,
	currentSite: new Site(),
	refreshSite: false,
};
