import React, { useEffect, useMemo, useState } from "react";
import { Modal, Form, Input, notification, Select, Radio, Button, Flex, RadioChangeEvent, message, Alert, Spin, } from "antd";
import { UserService } from "../../services";
import { FetchResponse, Site, UserDetails } from "../../models";
import { useStore } from "../../store";
import { ExclamationCircleFilled } from "@ant-design/icons";

const formItemLayout = {
	wrapperCol: {
		xs: { span: 8 },
		sm: { span: 8 },
	},
	labelCol: {
		xs: { span: 5 },
		sm: { span: 5 },
	},
};

export const UserProfilePage: React.FC = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { state: { currentSite, user }, dispatch } = useStore();

	const onFinish = (data: any) => {
		// form.resetFields();
	};

	const onFinishFailed = (data: any) => {
		console.log("Failed ", data);
	};

	useEffect(() => {
		console.log(user);
	}, []);

	const { confirm } = Modal;

	const showConfirm = async () => {
		confirm({
			title: `Confirmation`,
			icon: <ExclamationCircleFilled />,
			content: (
				<span>
					Etes-vous sûr de vouloir changer votre mot de passe ?
				</span>
			),
			okText: `Oui`,
			cancelText: 'Non',
			onOk: async () => {
				try {
					const values = { ...form.getFieldsValue(), id: user?.id };
					const res: FetchResponse = await UserService.changePassword(values);
					if (res.status == "success") {

						notification.success({ message: "Mot de passe changé avec succès", });
						setLoading(true);
						setTimeout(() => {
							setLoading(false);
							dispatch({ payload: true, type: 'LOGOUT' });
						}, 1000);
						form.resetFields();
					} else {
						notification.error({ message: res.message, });
					}
				} catch (error: any) {
					notification.error({ message: error?.message || "Erreur inconnue!", });
				}
			},
			onCancel() {
			},
		});
	};

	const onSubmit = () => {
		setLoading(true);
		form.validateFields()
			.then(showConfirm)
			.catch((e) => {
				console.log("Error ", e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onFieldsChange = (changed: any, values: any) => {
		if ("prices" in changed) {
			const prices = values.prices.reduce((prev: any[], curr: any, index: number) => {
				const _curr = { ...curr };
				if (index >= 1) {
					const last = prev[index - 1];

					if (last.to && _curr.from !== last.to + 1) {
						_curr.from = last.to + 1;
					}
				}
				prev.push(_curr);
				return prev;
			}, []);

			form.setFieldsValue({ ...form.getFieldsValue(), prices })
		}
	};

	return (
		<Spin size="large" spinning={loading}>
			<Form
				{...formItemLayout}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				onValuesChange={onFieldsChange}
				autoComplete="off"
				form={form}
				initialValues={{
					...user
				}}
			>
				<Input disabled name="id" id="id" type="hidden" />
				<Form.Item
					label="Nom"
					name="lastName"
					rules={[{ required: true, message: "Nom requis" }]}
				>
					<Input disabled name="lastname" id="lastname" />
				</Form.Item>
				<Form.Item
					label="Prenom"
					name="firstName"
					rules={[{ required: false }]}
				>
					<Input disabled name="firstname" id="firstname" />
				</Form.Item>
				<Form.Item
					label="Login"
					name="login"
					rules={[
						{ required: true, message: "Login requis" },
					]}
				>
					<Input disabled name="login" id="login" />
				</Form.Item>
				<Form.Item
					label="Mot de passe actuel"
					name="password"
					rules={[{ required: true, message: "Mot de passe actuel requis", }]}
				>
					<Input.Password name="password" id="password" />
				</Form.Item>
				<Form.Item
					label="Nouveau mot de passe"
					name="newpassword"
					rules={[{ required: true, message: "Nouveau mot de passe requis", }]}
				>
					<Input.Password name="newpassword" id="newpassword" />
				</Form.Item>
				<Form.Item
					label="Confirmation du nouveau mot de passe"
					name="confirmPassword"
					rules={[
						{ required: true, message: "Confirmation de mot de passe requis" },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('newpassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Les champs de mot de passe et de confirmation doivent correspondre. Merci de corriger'));
							},
						}),
					]}
				>
					<Input.Password name="confirmPassword" id="confirmPassword" />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 10 }}>
					<Flex gap="small">
						<Button type="primary" htmlType="submit" onClick={onSubmit} className="bg-blue">Changer le mot de passe</Button>
					</Flex>
				</Form.Item>
			</Form>
		</Spin>
	)
}